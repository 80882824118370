// const baseURL = 'https://localhost:44379/api/';
 const baseURL = 'https://pujaapi.sahajayogaindia.org.in/api/';

export const LOGIN_URL = `${baseURL}Account/login`;
export const ADD_UPDATE_BANK = `${baseURL}Masters/addeditbank`;
export const COUNTRY_URL = `${baseURL}Masters/getcountries`;
export const GET_COUNTRY_BY_ID = `${baseURL}Masters/getcountrybyid`;
export const ADD_UPDATE_COUNTRY = `${baseURL}Masters/addeditcountry`;
export const STATE_URL = `${baseURL}Masters/getstates`;
export const GET_STATE_BY_ID = `${baseURL}Masters/getstatebyid`;
export const GET_STATE_BY_COUNTRY_ID = `${baseURL}Masters/getstatesbycountryid`;
export const ADD_UPDATE_STATE = `${baseURL}Masters/addeditstate`;
export const DISTRICT_URL = `${baseURL}Masters/getdistricts`;
export const GET_DISTRICT_BY_STATE_ID = `${baseURL}Masters/getdistrictsbystateid`;
export const GET_DISTRICT_BY_ID = `${baseURL}Masters/getdistrictbyid`;
export const ADD_UPDATE_DISTRICT = `${baseURL}Masters/addeditdistrict`;
export const ADD_OTHER_DISTRICT = `${baseURL}Masters/addotherdistrict`;
export const DONATION_TYPE_URL = `${baseURL}Masters/getdonationtypes`;
export const GET_ACTIVE_DONATION_TYPES = `${baseURL}Masters/getactivedonationtypes`;
export const ADD_UPDATE_GROUP_LEADER = `${baseURL}Donors/addupdategroupleader`;
export const ADD_UPDATE_DONOR = `${baseURL}Donors/addupdatedonor`;
export const ADD_DONOR_PAYMENT = `${baseURL}Donors/adddonorpayment`;
export const GET_DONOR_BY_GROUP_ID = `${baseURL}Donors/getdonordetailsbygrpleaderid`;
export const GET_PAYMENT_BY_ID = `${baseURL}Donors/getpaymentdetailsbyid`;
export const GET_GROUP_LEADER_BY_ID = `${baseURL}Donors/getgroupleaderbyid`;
export const CHECK_MOBILENO_EXISTS = `${baseURL}Donors/checkmobilenoexists`;
export const CREATE_PAYMENT_ORDER = `${baseURL}Donors/createpaymentorder`;

// *********************************************************************************************
// export const LOGIN_URL = 'https://api.sahajayogaindia.org.in/api/Account/login`;
// export const ADD_UPDATE_BANK = 'https://api.sahajayogaindia.org.in/api/Masters/addeditbank`;
// export const COUNTRY_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getcountries`;
// export const GET_COUNTRY_BY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getcountrybyid`;
// export const ADD_UPDATE_COUNTRY = 'https://api.sahajayogaindia.org.in/api/Masters/addeditcountry`;
// export const STATE_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getstates`;
// export const GET_STATE_BY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getstatebyid`;
// export const GET_STATE_BY_COUNTRY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getstatesbycountryid`;
// export const ADD_UPDATE_STATE = 'https://api.sahajayogaindia.org.in/api/Masters/addeditstate`;
// export const DISTRICT_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getdistricts`;
// export const GET_DISTRICT_BY_STATE_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getdistrictsbystateid`;
// export const GET_DISTRICT_BY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getdistrictbyid`;
// export const ADD_UPDATE_DISTRICT = 'https://api.sahajayogaindia.org.in/api/Masters/addeditdistrict`;
// export const ADD_OTHER_DISTRICT = 'https://api.sahajayogaindia.org.in/api/Masters/addotherdistrict`;
// export const DONATION_TYPE_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getdonationtypes`;
// export const GET_ACTIVE_DONATION_TYPES = 'https://api.sahajayogaindia.org.in/api/Masters/getactivedonationtypes`;
// export const ADD_UPDATE_GROUP_LEADER = 'https://api.sahajayogaindia.org.in/api/Donors/addupdategroupleader`;
// export const ADD_UPDATE_DONOR = 'https://api.sahajayogaindia.org.in/api/Donors/addupdatedonor`;
// export const ADD_DONOR_PAYMENT = 'https://api.sahajayogaindia.org.in/api/Donors/adddonorpayment`;
// export const GET_DONOR_BY_GROUP_ID = 'https://api.sahajayogaindia.org.in/api/Donors/getdonordetailsbygrpleaderid`;
// export const GET_PAYMENT_BY_ID = 'https://api.sahajayogaindia.org.in/api/Donors/getpaymentdetailsbyid`;
// export const GET_GROUP_LEADER_BY_ID = 'https://api.sahajayogaindia.org.in/api/Donors/getgroupleaderbyid`;
// export const CHECK_MOBILENO_EXISTS = 'https://api.sahajayogaindia.org.in/api/Donors/checkmobilenoexists`;
// export const CREATE_PAYMENT_ORDER = 'https://api.sahajayogaindia.org.in/api/Donors/createpaymentorder`;

export default LOGIN_URL;