/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// @mui material components
import Card from "@mui/material/Card";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";
import Footer from "examples/Footer";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { Checkbox, Stack } from "@mui/material";
// Authentication layout components
// import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import shrimataji from "assets/images/shrimataji.jpg"
import Select from "react-select";
import { Oval } from 'react-loader-spinner';
import * as service from '../../../../services/service';


function Basic() {
  const [state, setState] = useState(
    {
      firstname: '', middlename: '', lastname: '', center: '', emailid: '', mobileno: '', registrationType: '', pan: '', leadcontribution: '0',
      firstname1: '', middlename1: '', lastname1: '', center1: '', pan1: '', contribution1: '0',
      firstname2: '', middlename2: '', lastname2: '', center2: '', pan2: '', contribution2: '0',
      firstname3: '', middlename3: '', lastname3: '', center3: '', pan3: '', contribution3: '0',
      firstname4: '', middlename4: '', lastname4: '', center4: '', pan4: '', contribution4: '0',
      firstname5: '', middlename5: '', lastname5: '', center5: '', pan5: '', contribution5: '0',
      otherdistrict: ''
    });
  const [countryId, setCountryId] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [stateId, setStateId] = useState('');
  const [stateData, setStateData] = useState([]);
  const [districtId, setDistrictId] = useState('');
  const [districtData, setDistrictData] = useState([]);
  const [donationTypeId, setDonationTypeId] = useState('');
  const [donationTypeData, setDonationTypeData] = useState([]);
  // const [bankId, setBankId] = useState('');
  // const [bankData, setBankData] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState('');
  const [donorType, setDonorType] = useState('');
  const [seniorCitizen, setSeniorCitizen] = useState(false);
  const [currency, setCurrency] = useState('');
  // const [paymentMode, setPaymentMode] = useState('');
  // const [paymentDate, setPaymentDate] = useState(new Date());
  const genderData = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];
  const donorTypeData = [{ label: "Adult", value: "Adult" }, { label: "Yuva", value: "Yuvashakti" }, { label: "Bal", value: "Balshakti" }];
  const currencyData = [{ label: "INR", value: "INR" }];
  // const paymentModeData = [{ label: "Cash", value: "Cash" }, { label: "Bank Transfer", value: "Bank Transfer" }];
  const [adultContri, setAdultContri] = useState('');
  const [yuvaContri, setYuvaContri] = useState('');
  const [balContri, setBalContri] = useState('');
  const [gender1, setGender1] = useState('');
  const [donorType1, setDonorType1] = useState('');
  const [seniorCitizen1, setSeniorCitizen1] = useState(false);
  const [gender2, setGender2] = useState('');
  const [donorType2, setDonorType2] = useState('');
  const [seniorCitizen2, setSeniorCitizen2] = useState(false);
  const [gender3, setGender3] = useState('');
  const [donorType3, setDonorType3] = useState('');
  const [seniorCitizen3, setSeniorCitizen3] = useState(false);
  const [gender4, setGender4] = useState('');
  const [donorType4, setDonorType4] = useState('');
  const [seniorCitizen4, setSeniorCitizen4] = useState(false);
  const [gender5, setGender5] = useState('');
  const [donorType5, setDonorType5] = useState('');
  const [seniorCitizen5, setSeniorCitizen5] = useState(false);
  const [contribution, setContribution] = useState(0);
  const [addMore1, setAddMore1] = useState(false);
  const [addMore2, setAddMore2] = useState(false);
  const [addMore3, setAddMore3] = useState(false);
  const [addMore4, setAddMore4] = useState(false);
  const [addMore5, setAddMore5] = useState(false);
  // const [otherDist, setOtherDist] = useState(false);

  const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

  const navigate = useNavigate();
  // const params = useParams();
  const getDonationTypes = () => {
    fetch(
      service.DONATION_TYPE_URL,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(res => res.json())
      .then(result => {
        const data2 = result.map((item) => ({ value: item.donationTypeId, label: item.name }));
        setDonationTypeData(data2);
        // assuming single database per puja
        setAdultContri(result[0].adultContri);
        setYuvaContri(result[0].yuvaContri);
        setBalContri(result[0].balContri);

        // updated charges from 16 sept 2022
        // setAdultContri(2500);
        // setYuvaContri(1800);
        // setBalContri(1200);

      });
  };

  const getCountries = () => {
    fetch(
      service.COUNTRY_URL,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(res => res.json())
      .then(result => {
        const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
        setCountryData(data2);
      });
  };
  const getStates = (id) => {
    fetch(
      service.GET_STATE_BY_COUNTRY_ID,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          CountryId: id,
        })
      })
      .then(res => res.json())
      .then(result => {
        const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
        setStateData(data2);
      });
  };

  const getDistricts = (id) => {
    fetch(
      service.GET_DISTRICT_BY_STATE_ID,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          StateId: id,
        })
      })
      .then(res => res.json())
      .then(result => {
        const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
        setDistrictData(data2);
      });
  };
  // const getBanks = () => {
  //   fetch(
  //     service.BANK_URL,
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //     })
  //     .then(res => res.json())
  //     .then(result => {
  //       const data2 = result.map((item) => ({ value: item.bankId, label: item.bank }));
  //       setBankData(data2);
  //     });
  // };

  useEffect(() => {
    // getBanks();
    getCountries();
    getDonationTypes();
    const def = { value: 1, label: 'India' };
    setCountryId(def);
    getStates(def.value);

    const cur = { label: 'INR', value: 'INR' };
    setCurrency(cur);

    // const mode = { label: 'Cash', value: 'Cash' };
    // setPaymentMode(mode);

    const gen = { label: 'Male', value: 'Male' };
    setGender(gen);
    setGender1(gen);
    setGender2(gen);
    setGender3(gen);
    setGender4(gen);
    setGender5(gen);

    const type = { value: 1, label: 'Diwali Puja 2024' };
    setDonationTypeId(type);
  }, []);

  function GetContribution(val) {
    let contri = 0;
    if (val === "Adult") {
      contri = adultContri;
    }
    else if (val === "Yuvashakti") {
      contri = yuvaContri;
    }
    else if (val === "Balshakti") {
      contri = balContri;
    }
    // const contrib = contribution + contri;
    // setContribution(contrib)
    return contri;
  }

  const donorTypeChangeHandler = (ev) => {
    const contri = GetContribution(ev.value);
    state.leadcontribution = contri;

    const contrib = contri + parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10)
      + parseInt(state.contribution4, 10) + parseInt(state.contribution5, 10);
    setContribution(contrib);
    setDonorType(ev);
  }

  const donorType1ChangeHandler = (ev) => {
    const contri = GetContribution(ev.value);
    state.contribution1 = contri;

    const contrib = parseInt(state.leadcontribution, 10) + contri + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10)
      + parseInt(state.contribution4, 10) + parseInt(state.contribution5, 10);
    setContribution(contrib);
    setDonorType1(ev);
  }

  const donorType2ChangeHandler = (ev) => {
    const contri = GetContribution(ev.value);
    state.contribution2 = contri;

    const contrib = parseInt(state.leadcontribution, 10) + parseInt(state.contribution1, 10) + contri + parseInt(state.contribution3, 10)
      + parseInt(state.contribution4, 10) + parseInt(state.contribution5, 10);
    setContribution(contrib);
    setDonorType2(ev);
  }

  const donorType3ChangeHandler = (ev) => {
    const contri = GetContribution(ev.value);
    state.contribution3 = contri;

    const contrib = parseInt(state.leadcontribution, 10) + parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10)
      + contri + parseInt(state.contribution4, 10) + parseInt(state.contribution5, 10);
    setContribution(contrib);
    setDonorType3(ev);
  }

  const donorType4ChangeHandler = (ev) => {
    const contri = GetContribution(ev.value);
    state.contribution4 = contri;

    const contrib = parseInt(state.leadcontribution, 10) + parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10)
      + parseInt(state.contribution3, 10) + contri + parseInt(state.contribution5, 10);
    setContribution(contrib);
    setDonorType4(ev);
  }
  const donorType5ChangeHandler = (ev) => {
    const contri = GetContribution(ev.value);
    state.contribution5 = contri;

    const contrib = parseInt(state.leadcontribution, 10) + parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10)
      + parseInt(state.contribution3, 10) + parseInt(state.contribution4, 10) + contri;
    setContribution(contrib);
    setDonorType5(ev);
  }

  const seniorCitizen1ChangeHandler = (ev) => {
    setSeniorCitizen1(ev.target.checked);
  }
  const seniorCitizen2ChangeHandler = (ev) => {
    setSeniorCitizen2(ev.target.checked);
  }
  const seniorCitizen3ChangeHandler = (ev) => {
    setSeniorCitizen3(ev.target.checked);
  }
  const seniorCitizen4ChangeHandler = (ev) => {
    setSeniorCitizen4(ev.target.checked);
  }
  const seniorCitizen5ChangeHandler = (ev) => {
    setSeniorCitizen5(ev.target.checked);
  }
  const validate = () => {
    let isValid = true;
    const errs = {};
    if (!state.firstname) {
      isValid = false;
      errs[".firstname"] = "Enter first name";
    }
    if (!state.lastname) {
      isValid = false;
      errs[".lastname"] = "Enter last name";
    }
    if (!donorType) {
      isValid = false;
      errs[".donorType"] = "Select type";
    }
    if (!stateId) {
      isValid = false;
      errs[".state"] = "Please select state";
    }
    if (!districtId && !state.otherdistrict) {
      isValid = false;
      errs[".district"] = "Please select district or enter district if its not present in the list";
    }
    if (!state.emailid) {
      isValid = false;
      errs[".emailId"] = "Please enter emailId";
    }
    if (!state.mobileno) {
      isValid = false;
      errs[".mobileno"] = "Please enter mobile no.";
    }
    if (!state.pan) {
      isValid = false;
      errs[".pan"] = "Please enter PAN";
    }
    if (!contribution) {
      isValid = false;
      errs[".contribution"] = "Please enter contribution";
    }
    if (addMore1 && !state.firstname1) {
      isValid = false;
      errs[".firstname1"] = "Enter first name";
    }
    if (addMore1 && !state.lastname1) {
      isValid = false;
      errs[".lastname1"] = "Enter last name";
    }
    if (addMore1 && !donorType1) {
      isValid = false;
      errs[".donorType1"] = "Select type";
    }
    if (addMore1 && !state.pan1) {
      isValid = false;
      errs[".pan1"] = "Please enter PAN";
    }

    if (addMore2 && !state.firstname2) {
      isValid = false;
      errs[".firstname2"] = "Enter first name";
    }
    if (addMore2 && !state.lastname2) {
      isValid = false;
      errs[".lastname2"] = "Enter last name";
    }
    if (addMore2 && !donorType2) {
      isValid = false;
      errs[".donorType2"] = "Select type";
    }
    if (addMore2 && !state.pan2) {
      isValid = false;
      errs[".pan2"] = "Please enter PAN";
    }

    if (addMore3 && !state.firstname3) {
      isValid = false;
      errs[".firstname3"] = "Enter first name";
    }
    if (addMore3 && !state.lastname3) {
      isValid = false;
      errs[".lastname3"] = "Enter last name";
    }
    if (addMore3 && !donorType3) {
      isValid = false;
      errs[".donorType3"] = "Select type";
    }
    if (addMore3 && !state.pan3) {
      isValid = false;
      errs[".pan3"] = "Please enter PAN";
    }

    if (addMore4 && !state.firstname4) {
      isValid = false;
      errs[".firstname4"] = "Enter first name";
    }
    if (addMore4 && !state.lastname4) {
      isValid = false;
      errs[".lastname4"] = "Enter last name";
    }
    if (addMore4 && !donorType4) {
      isValid = false;
      errs[".donorType4"] = "Select type";
    }
    if (addMore4 && !state.pan4) {
      isValid = false;
      errs[".pan4"] = "Please enter PAN";
    }

    if (addMore5 && !state.firstname5) {
      isValid = false;
      errs[".firstname5"] = "Enter first name";
    }
    if (addMore5 && !state.lastname5) {
      isValid = false;
      errs[".lastname5"] = "Enter last name";
    }
    if (addMore5 && !donorType5) {
      isValid = false;
      errs[".donorType5"] = "Select type";
    }
    if (addMore5 && !state.pan5) {
      isValid = false;
      errs[".pan5"] = "Please enter PAN";
    }
    setErrors(errs);
    return isValid;
  }

  const changeHandler = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const countryChangeHandler = (ev) => {
    setCountryId(ev);
    setStateId(null);
    setDistrictId(null);
    getStates(ev.value);
  }
  const stateChangeHandler = (ev) => {
    setStateId(ev);
    setDistrictId(null);
    getDistricts(ev.value);
  }
  const districtChangeHandler = (ev) => {
    setDistrictId(ev);
  }
  const donationChangeHandler = (ev) => {
    setDonationTypeId(ev);
  }
  // const bankChangeHandler = (ev) => {
  //   setBankId(ev);
  // }

  const seniorCitizenChangeHandler = (ev) => {
    setSeniorCitizen(ev.target.checked);
  }

  // const otherDistrictHandler = (val) => {
  //   setOtherDist(val);
  // }

  const addMore1Handler = (val) => {
    setAddMore1(val);
  }
  const addMore2Handler = (val) => {
    setAddMore2(val);
  }
  const addMore3Handler = (val) => {
    setAddMore3(val);
  }
  const addMore4Handler = (val) => {
    setAddMore4(val);
  }
  const addMore5Handler = (val) => {
    setAddMore5(val);
  }

  function AddDonor(groupLeadId, firstName, middleName, lastName, cntryId, statId, distrId, cntr, gndr, pan, dnrType, senior, paymentId, contri) {
    fetch(
      service.ADD_UPDATE_DONOR,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          DonorId: 0,
          GroupLeaderId: groupLeadId,
          FirstName: firstName,
          MiddleName: middleName,
          LastName: lastName,
          CountryId: cntryId,
          StateId: statId,
          DistrictId: distrId,
          Center: cntr,
          Gender: gndr,
          PAN: pan,
          DonorType: dnrType,
          IsSeniorCitizen: senior,
          IsAccomodationRequired: true,
          PaymentId: paymentId,
          Contribution: contri,
          AddedBy: groupLeadId,
          UpdatedBy: groupLeadId,
        })
      })
      .then(res => res.json())
      .then(result => {
        if (result.message.messageType === 'Failed') {
          // eslint-disable-next-line
          alert(result.message.message);
        }
      });
  }

  function AddDonors(grpLeadId, paymentId, distId) {
    // Add main donors 
    AddDonor(grpLeadId, state.firstname, state.middlename, state.lastname, countryId.value, stateId.value, distId,
      state.center, gender.value, state.pan, donorType.value, seniorCitizen, paymentId, state.leadcontribution);

    // Add donors 1
    if (addMore1 && state.firstname1 !== "" && state.lastname1 !== "" && state.contribution1 !== "") {
      AddDonor(grpLeadId, state.firstname1, state.middlename1, state.lastname1, countryId.value, stateId.value, distId,
        state.center1, gender1.value, state.pan1, donorType1.value, seniorCitizen1, paymentId, state.contribution1)
    }
    // Add donors 2
    if (addMore2 && state.firstname2 !== "" && state.lastname2 !== "" && state.contribution2 !== "") {
      AddDonor(grpLeadId, state.firstname2, state.middlename2, state.lastname2, countryId.value, stateId.value, distId,
        state.center2, gender2.value, state.pan2, donorType2.value, seniorCitizen2, paymentId, state.contribution2)
    }
    // Add donors 3
    if (addMore3 && state.firstname3 !== "" && state.lastname3 !== "" && state.contribution3 !== "") {
      AddDonor(grpLeadId, state.firstname3, state.middlename3, state.lastname3, countryId.value, stateId.value, distId,
        state.center3, gender3.value, state.pan3, donorType3.value, seniorCitizen3, paymentId, state.contribution3)
    }
    // Add donors 4
    if (addMore4 && state.firstname4 !== "" && state.lastname4 !== "" && state.contribution4 !== "") {
      AddDonor(grpLeadId, state.firstname4, state.middlename4, state.lastname4, countryId.value, stateId.value, distId,
        state.center4, gender4.value, state.pan4, donorType4.value, seniorCitizen4, paymentId, state.contribution4)
    }
    // Add donors 5
    if (addMore5 && state.firstname5 !== "" && state.lastname5 !== "" && state.contribution5 !== "") {
      AddDonor(grpLeadId, state.firstname5, state.middlename5, state.lastname5, countryId.value, stateId.value, distId,
        state.center5, gender5.value, state.pan5, donorType5.value, seniorCitizen5, paymentId, state.contribution5)
    }
    // eslint-disable-next-line
    // alert("Data Added Successfully.");
    // window.location.reload();
    navigate(`/thanks/${grpLeadId}/${paymentId}`);
  }

  function AddPayment(grpLeadId, razorOrderId, razorPaymentId, razorSignature, distId) {
    const errs = {};
    fetch(
      service.ADD_DONOR_PAYMENT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          GroupLeaderId: grpLeadId,
          DonationTypeId: donationTypeId.value,
          Contribution: contribution,
          Currency: currency.value,
          PaymentMode: 'Online',
          PaymentDate: moment(new Date()).format("MM/DD/YYYY"),
          UTRCode: '',
          BankId: 0,
          RazorOrderId: razorOrderId,
          RazorPaymentId: razorPaymentId,
          RazorSignature: razorSignature,
          AddedBy: grpLeadId,
          UpdatedBy: grpLeadId,
        })
      })
      .then(res => res.json())
      .then(resultP => {
        if (resultP.message.messageType === 'Success') {
          AddDonors(grpLeadId, resultP.paymentId, distId);
        }
        else if (resultP.message.messageType === 'Failed') {
          // eslint-disable-next-line
          alert(resultP.message.message);
        }
        else {
          errs[".invalid"] = resultP.message.message;
          setErrors(errs);
        }
      });
  }
  function AddGroupLeader(razorOrderId, razorPaymentId, razorSignature) {
    if (!districtId && state.otherdistrict) {
      fetch(
        service.ADD_OTHER_DISTRICT,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            District: state.otherdistrict,
            CountryId: countryId.value,
            StateId: stateId.value,
            AddedBy: 0,
            UpdatedBy: 0,
          })
        })
        .then(dis => dis.json())
        .then(dist => {
          if (dist.messageType === 'Success') {
            fetch(
              service.ADD_UPDATE_GROUP_LEADER,
              {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  GroupLeaderId: 0,
                  FirstName: state.firstname,
                  MiddleName: state.middlename,
                  LastName: state.lastname,
                  CountryId: countryId.value,
                  StateId: stateId.value,
                  DistrictId: dist.id,
                  Center: state.center,
                  MobileNo: state.mobileno,
                  EmailId: state.emailid,
                  Gender: gender.value,
                  PAN: state.pan,
                  RegistrationType: addMore1 ? 'GroupLeaderBySelf' : 'IndividualBySelf', // IndividualByAdmin, GroupLeaderBySelf, IndividualBySelf 
                  AddedBy: 0,
                  UpdatedBy: 0,
                })
              })
              .then(res => res.json())
              .then(result => {
                if (result.message.messageType === 'Success') {
                  AddPayment(result.groupLeaderId, razorOrderId, razorPaymentId, razorSignature, dist.id);
                }
                else if (result.message.messageType === 'Failed') {
                  // eslint-disable-next-line
                  alert(result.message.message);
                }
              });
          }
          else {
            // eslint-disable-next-line
            alert(result.message.message);
          }
        });
    }
    else {
      fetch(
        service.ADD_UPDATE_GROUP_LEADER,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            GroupLeaderId: 0,
            FirstName: state.firstname,
            MiddleName: state.middlename,
            LastName: state.lastname,
            CountryId: countryId.value,
            StateId: stateId.value,
            DistrictId: districtId.value,
            Center: state.center,
            MobileNo: state.mobileno,
            EmailId: state.emailid,
            Gender: gender.value,
            PAN: state.pan,
            RegistrationType: addMore1 || addMore2 || addMore3 || addMore4 || addMore5 ? 'GroupLeaderBySelf' : 'IndividualBySelf', // IndividualByAdmin, GroupLeaderBySelf, IndividualBySelf 
            AddedBy: 0,
            UpdatedBy: 0,
          })
        })
        .then(res => res.json())
        .then(result => {
          if (result.message.messageType === 'Success') {
            AddPayment(result.groupLeaderId, razorOrderId, razorPaymentId, razorSignature, districtId.value);
          }
          else if (result.message.messageType === 'Failed') {
            // eslint-disable-next-line
            alert(result.message.message);
          }
        });
    }
  }

  const handleConfirmResponse = (res) => {        
     AddGroupLeader(res.razorpay_order_id, res.razorpay_payment_id, res.razorpay_signature);         
  };

  const handleConfirm = (orderId) => {
    const options = {
      key: process.env.REACT_APP_RAZOR_KEY_ID,
      amount: contribution * 100,
      name: 'Puja Registration',
      description: 'Puja Registration',
      image: '',
      order_id: orderId,
      handler: handleConfirmResponse,
      prefill: {
        name: state.firstname + state.lastname,
        email: state.emailid,
        contact: state.mobileno
      },
      theme: {
        color: '#F37254'
      }
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();  
  };

  function CreateOrder() {
    let orderId = 0;
    fetch(
      service.CREATE_PAYMENT_ORDER,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Currency: currency.value,
          Contribution: contribution * 100
        })
      })
      .then((res) => res.json())
      .then((data) => {
        orderId = data.id
        // console.log(data);
        handleConfirm(orderId)
      })
      .catch(err => {
        // eslint-disable-next-line
        alert("Error...", err);
      });         
  }

  const handleRazorpayPayment = () => {
     setLoading(true);
      if (validate()) {
      fetch(
        service.CHECK_MOBILENO_EXISTS,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            MobileNo: state.mobileno
          })
        })
        .then((res) => res.json())
        .then((result) => {
          if (result.messageType === 'Failed') {
            // eslint-disable-next-line
            alert(result.message);
          }
          else if (result.messageType === 'Success') {
            CreateOrder();
          }
        });
     }
     setLoading(false);    
  };

  return (
    <div style={{ width: '100%' }}>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Puja Registration
                </MDTypography>
              </MDBox>
              <Card style={{ alignItems: 'center' }}>
                <br />
                <div alignSelf="center" style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>Please use laptop/desktop for registration</div>
                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>
                          <MDInput label="First Name" fullWidth value={state.firstname || ''}
                            name="firstname"
                            onChange={changeHandler}
                            id="firstname"
                            required />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>
                          <MDInput label="Middle Name" fullWidth value={state.middlename || ''}
                            name="middlename"
                            onChange={changeHandler}
                            id="middlename"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: '35%' }}>
                          <MDInput label="Last Name" fullWidth value={state.lastname || ''}
                            name="lastname"
                            onChange={changeHandler}
                            id="lastname"
                            required />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Dropdown menuClassName='myMenuClassName' id="country"
                            name="country"
                            options={countryData}
                            value={countryId}
                            onChange={countryChangeHandler}
                            label="Country"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Select
                            options={stateData}
                            name="state"
                            value={stateId}
                            onChange={stateChangeHandler}
                            placeholder="Select State"
                            isSearchable
                            styles={selectStyles}
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Stack direction="row" spacing={5}>
                            <div style={{ width: '50%' }}>
                              <Select className="basic-single"
                                options={districtData}
                                name="district"
                                value={districtId}
                                onChange={districtChangeHandler}
                                isSearchable
                                placeholder="Select District"
                                styles={selectStyles}
                              />
                            </div>
                            <MDInput label="Other" value={state.otherdistrict || ''}
                              name="otherdistrict"
                              onChange={changeHandler}
                              id="otherdistrict" />
                          </Stack>
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Mobile No (WhatsApp No)" fullWidth value={state.mobileno || ''}
                            name="mobileno"
                            onChange={changeHandler}
                            id="mobileno"
                            required
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Email" fullWidth value={state.emailid || ''}
                            name="emailid"
                            type="email"
                            onChange={changeHandler}
                            id="emailid"
                            required
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Center" fullWidth value={state.center || ''}
                            name="center"
                            onChange={changeHandler}
                            id="center"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Dropdown menuClassName='myMenuClassName' id="gender"
                            name="gender"
                            options={genderData}
                            value={gender}
                            onChange={ev => setGender(ev)}
                            placeholder="Select Gender"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="PAN" fullWidth value={state.pan || ''}
                            name="pan"
                            onChange={changeHandler}
                            id="pan"
                            required
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Dropdown menuClassName='myMenuClassName' id="donorType"
                            name="donorType"
                            options={donorTypeData}
                            value={donorType}
                            onChange={donorTypeChangeHandler}
                            placeholder="Type"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Senior Citizen
                          <Checkbox menuClassName='myMenuClassName' id="seniorCitizen"
                            name="seniorCitizen"
                            checked={seniorCitizen}
                            onChange={seniorCitizenChangeHandler}
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Contribution" fullWidth value={state.leadcontribution}
                            name="leadcontribution"
                            onChange={changeHandler}
                            id="leadcontribution"
                            required
                            disabled
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".leadcontribution"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <MDButton onClick={() => addMore1Handler(true)} disabled={addMore1}>
                            Add Member
                          </MDButton>
                        </TableCell>
                      </TableRow>
                      {addMore1 ?
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="First Name" fullWidth value={state.firstname1 || ''}
                                      name="firstname1"
                                      onChange={changeHandler}
                                      id="firstname1"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname1"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Middle Name" fullWidth value={state.middlename1 || ''}
                                      name="middlename1"
                                      onChange={changeHandler}
                                      id="middlename1"
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename1"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Last Name" fullWidth value={state.lastname1 || ''}
                                      name="lastname1"
                                      onChange={changeHandler}
                                      id="lastname1"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname1"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Center" fullWidth value={state.center1 || ''}
                                      name="center1"
                                      onChange={changeHandler}
                                      id="center1"
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center1"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="PAN" fullWidth value={state.pan1 || ''}
                                      name="pan1"
                                      onChange={changeHandler}
                                      id="pan1"
                                      required
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan1"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="gender1"
                                        name="gender1"
                                        options={genderData}
                                        value={gender1}
                                        onChange={ev => setGender1(ev)}
                                        placeholder="Select Gender"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender1"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="donorType1"
                                        name="donorType1"
                                        options={donorTypeData}
                                        value={donorType1}
                                        onChange={donorType1ChangeHandler}
                                        placeholder="Type"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType1"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px', width: '80px' }}>
                                      Senior Citizen
                                      <Checkbox menuClassName='myMenuClassName' id="seniorCitizen1"
                                        name="seniorCitizen1"
                                        checked={seniorCitizen1}
                                        onChange={seniorCitizen1ChangeHandler}
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen1"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Contribution" fullWidth value={state.contribution1}
                                      name="contribution1"
                                      onChange={changeHandler}
                                      id="contribution1"
                                      required
                                      disabled
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution1"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDButton onClick={() => addMore1Handler(false)} disabled={!addMore1}>
                                      Remove
                                    </MDButton>
                                    <MDButton onClick={() => addMore2Handler(true)} disabled={addMore2}>
                                      Add More
                                    </MDButton>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                        : null}
                      {addMore2 ?
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="First Name" fullWidth value={state.firstname2 || ''}
                                      name="firstname2"
                                      onChange={changeHandler}
                                      id="firstname2"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname2"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Middle Name" fullWidth value={state.middlename2 || ''}
                                      name="middlename2"
                                      onChange={changeHandler}
                                      id="middlename2"
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename2"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Last Name" fullWidth value={state.lastname2 || ''}
                                      name="lastname2"
                                      onChange={changeHandler}
                                      id="lastname2"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname2"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Center" fullWidth value={state.center2 || ''}
                                      name="center2"
                                      onChange={changeHandler}
                                      id="center2"
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center2"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="PAN" fullWidth value={state.pan2 || ''}
                                      name="pan2"
                                      onChange={changeHandler}
                                      id="pan2"
                                      required
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan2"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="gender2"
                                        name="gender2"
                                        options={genderData}
                                        value={gender2}
                                        onChange={ev => setGender2(ev)}
                                        placeholder="Select Gender"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender2"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="donorType2"
                                        name="donorType2"
                                        options={donorTypeData}
                                        value={donorType2}
                                        onChange={donorType2ChangeHandler}
                                        placeholder="Type"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType2"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px', width: '80px' }}>
                                      <Checkbox menuClassName='myMenuClassName' id="seniorCitizen2"
                                        name="seniorCitizen2"
                                        value={seniorCitizen2}
                                        onChange={seniorCitizen2ChangeHandler}
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen2"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Contribution" fullWidth value={state.contribution2}
                                      name="contribution2"
                                      onChange={changeHandler}
                                      id="contribution2"
                                      required
                                      disabled
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution2"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDButton onClick={() => addMore2Handler(false)} disabled={!addMore2}>
                                      Remove
                                    </MDButton>
                                    <MDButton onClick={() => addMore3Handler(true)} disabled={addMore3}>
                                      Add More
                                    </MDButton>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                        : null}
                      {addMore3 ?
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="First Name" fullWidth value={state.firstname3 || ''}
                                      name="firstname3"
                                      onChange={changeHandler}
                                      id="firstname3"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname3"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Middle Name" fullWidth value={state.middlename3 || ''}
                                      name="middlename3"
                                      onChange={changeHandler}
                                      id="middlename3"
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename3"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Last Name" fullWidth value={state.lastname3 || ''}
                                      name="lastname3"
                                      onChange={changeHandler}
                                      id="lastname3"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname3"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Center" fullWidth value={state.center3 || ''}
                                      name="center3"
                                      onChange={changeHandler}
                                      id="center3"
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center3"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="PAN" fullWidth value={state.pan3 || ''}
                                      name="pan3"
                                      onChange={changeHandler}
                                      id="pan3"
                                      required
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan3"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="gender3"
                                        name="gender3"
                                        options={genderData}
                                        value={gender3}
                                        onChange={ev => setGender3(ev)}
                                        placeholder="Select Gender"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender3"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="donorType3"
                                        name="donorType3"
                                        options={donorTypeData}
                                        value={donorType3}
                                        onChange={donorType3ChangeHandler}
                                        placeholder="Type"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType3"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px', width: '80px' }}>
                                      <Checkbox menuClassName='myMenuClassName' id="seniorCitizen3"
                                        name="seniorCitizen3"
                                        value={seniorCitizen3}
                                        onChange={seniorCitizen3ChangeHandler}
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen3"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Contribution" fullWidth value={state.contribution3}
                                      name="contribution3"
                                      onChange={changeHandler}
                                      id="contribution3"
                                      required
                                      disabled
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution3"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDButton onClick={() => addMore3Handler(false)} disabled={!addMore3}>
                                      Remove
                                    </MDButton>
                                    <MDButton onClick={() => addMore4Handler(true)} disabled={addMore4}>
                                      Add More
                                    </MDButton>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                        : null}
                      {addMore4 ?
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="First Name" fullWidth value={state.firstname4 || ''}
                                      name="firstname4"
                                      onChange={changeHandler}
                                      id="firstname4"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname4"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Middle Name" fullWidth value={state.middlename4 || ''}
                                      name="middlename4"
                                      onChange={changeHandler}
                                      id="middlename4"
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename4"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Last Name" fullWidth value={state.lastname4 || ''}
                                      name="lastname4"
                                      onChange={changeHandler}
                                      id="lastname4"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname4"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Center" fullWidth value={state.center4 || ''}
                                      name="center4"
                                      onChange={changeHandler}
                                      id="center4"
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center4"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="PAN" fullWidth value={state.pan4 || ''}
                                      name="pan4"
                                      onChange={changeHandler}
                                      id="pan4"
                                      required
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan4"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="gender4"
                                        name="gender4"
                                        options={genderData}
                                        value={gender4}
                                        onChange={ev => setGender4(ev)}
                                        placeholder="Select Gender"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender4"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="donorType4"
                                        name="donorType4"
                                        options={donorTypeData}
                                        value={donorType4}
                                        onChange={donorType4ChangeHandler}
                                        placeholder="Type"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType4"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Checkbox menuClassName='myMenuClassName' id="seniorCitizen4"
                                        name="seniorCitizen4"
                                        value={seniorCitizen4}
                                        onChange={seniorCitizen4ChangeHandler}
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen4"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Contribution" fullWidth value={state.contribution4}
                                      name="contribution4"
                                      onChange={changeHandler}
                                      id="contribution4"
                                      required
                                      disabled
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution4"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDButton onClick={() => addMore4Handler(false)} disabled={!addMore4}>
                                      Remove
                                    </MDButton>
                                    <MDButton onClick={() => addMore5Handler(true)} disabled={addMore5}>
                                      Add More
                                    </MDButton>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                        : null}
                      {addMore5 ?
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="First Name" fullWidth value={state.firstname5 || ''}
                                      name="firstname5"
                                      onChange={changeHandler}
                                      id="firstname5"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname5"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Middle Name" fullWidth value={state.middlename5 || ''}
                                      name="middlename5"
                                      onChange={changeHandler}
                                      id="middlename5"
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename5"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Last Name" fullWidth value={state.lastname5 || ''}
                                      name="lastname5"
                                      onChange={changeHandler}
                                      id="lastname5"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname5"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Center" fullWidth value={state.center5 || ''}
                                      name="center5"
                                      onChange={changeHandler}
                                      id="center5" />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center5"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="PAN" fullWidth value={state.pan5 || ''}
                                      name="pan5"
                                      onChange={changeHandler}
                                      id="pan5"
                                      required />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan5"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="gender5"
                                        name="gender5"
                                        options={genderData}
                                        value={gender5}
                                        onChange={ev => setGender5(ev)}
                                        placeholder="Select Gender"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender5"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Dropdown menuClassName='myMenuClassName' id="donorType5"
                                        name="donorType5"
                                        options={donorTypeData}
                                        value={donorType5}
                                        onChange={donorType5ChangeHandler}
                                        placeholder="Type"
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType5"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ fontSize: '12px' }}>
                                      <Checkbox menuClassName='myMenuClassName' id="seniorCitizen5"
                                        name="seniorCitizen5"
                                        value={seniorCitizen5}
                                        onChange={seniorCitizen5ChangeHandler}
                                      />
                                    </div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen5"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDInput label="Contribution" fullWidth value={state.contribution5}
                                      name="contribution5"
                                      onChange={changeHandler}
                                      id="contribution5"
                                      required
                                      disabled
                                    />
                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution5"]}</div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <MDButton onClick={() => addMore5Handler(false)} disabled={!addMore5}>
                                      Remove
                                    </MDButton>
                                  </TableCell>
                                  <TableCell />
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                        : null}
                    </TableBody>
                  </Table>
                  <hr />
                  <br />
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Payment Details</div>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Dropdown menuClassName='myMenuClassName' id="donationType"
                            name="donationType"
                            options={donationTypeData}
                            value={donationTypeId}
                            onChange={donationChangeHandler}
                            label="Registration Type"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donationType"]}</div>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <div style={{ fontSize: '13px', color: 'green', fontWeight: 'bold' }}>Adult Contribution : {adultContri}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Yuva Contribution : {yuvaContri}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Balshakti Contribution : {balContri}
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Contribution" fullWidth value={contribution}
                            name="contribution"
                            onChange={changeHandler}
                            id="contribution"
                            required
                            disabled
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution"]}</div>
                        </TableCell>
                        <TableCell style={{ width: '20%' }}>
                          <Dropdown menuClassName='myMenuClassName' id="currency"
                            name="currency"
                            options={currencyData}
                            value={currency}
                            onChange={ev => setCurrency(ev)}
                            disabled
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".currency"]}</div>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <MDBox mt={4} mb={1} textAlign="center">
                  {loading ? <Oval
                    heigth="20"
                    width="20"
                    color='grey'
                    ariaLabel='loading'
                  /> :
                    <MDButton color="info" onClick={handleRazorpayPayment}>
                      Pay Now
                    </MDButton>
                  }
                  {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                </MDBox>

              </Card>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </div>
  );
}


export default Basic;
